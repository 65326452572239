<script lang="ts" setup>
import { useModal } from "vue-final-modal";
import DemoLMSInfo from "~/components/Demos/LMSInfo.vue";

const userClassroomDataStore = useUserClassroomsDataStore();
const { visibleClassrooms } = storeToRefs(userClassroomDataStore);

const appUserStore = useAppUserData();
const { demoMode, isPublicDemo } = storeToRefs(appUserStore);

const tabs = ref<Tabs>([
  {
    label: "IEW Gradebook",
    key: "iew",
  },
  {
    label: "3rd Party LMS",
    key: "google",
  },
]);

const showLMSExplanationModal = () => {
  const { open, close } = useModal({
    component: DemoLMSInfo,
    attrs: {
      onClose: () => {
        close();
      },
    },
  });

  open();
};
</script>

<template>
  <div v-if="visibleClassrooms" class="flex flex-col w-full">
    <ClassroomFoldersListItem
      v-for="classroom of visibleClassrooms"
      :key="classroom.id"
      :classroom="classroom"
      class="mb-2"
    />
    <div
      v-if="isPublicDemo"
      class="flex flex-col m-1 border-2 border-primary-default border-dashed rounded p-2"
    >
      <p class="text-left px-1 mb-2">
        This is what IEW Gradebook classrooms looks like when students directly
        submit to
      </p>
      <div
        class="mb-2 border rounded border-primary-default p-2 text-center cursor-pointer hover:bg-primary-default hover:bg-opacity-20"
        :class="{
          ' text-primary-on bg-primary-default !hover:bg-opacity-40':
            demoMode === 'iew',
          ' text-primary-default bg-surface': demoMode != 'iew',
        }"
        @click="demoMode = 'iew'"
      >
        IEW Gradebook
      </div>
      <div
        class="mb-2 border rounded border-primary-default p-2 text-center cursor-pointer hover:bg-primary-default hover:bg-opacity-20"
        :class="{
          ' text-primary-on bg-primary-default !hover:bg-opacity-40':
            demoMode === 'google',
          ' text-primary-default bg-surface': demoMode != 'google',
        }"
        @click="demoMode = 'google'"
      >
        Google Classroom
      </div>
      <div />
      <BaseTextButton @click="showLMSExplanationModal">
        Learn More
      </BaseTextButton>
    </div>
  </div>
</template>

<style scoped></style>
