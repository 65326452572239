<script lang="ts" setup>
import { AssignmentsService } from "classes/models/assignments/assignments.service";
import { Subscription } from "rxjs";
import { Checklists } from "types/Checklist";
import { VueFinalModal } from "vue-final-modal";

const emit = defineEmits(["close"]);

const checklists = ref<Checklists>([]);

const subscription = ref<Subscription | undefined>();

onMounted(() => {
  subscription.value = useUserChecklists()
    .streamUserChecklists()
    .subscribe((newChecklists) => {
      checklists.value = newChecklists;
    });
});

onUnmounted(() => {
  subscription.value?.unsubscribe();
});

const userClassroomsData = useUserClassroomsDataStore();

const assignmentsWithoutChecklistGroupedByClassroom =
  userClassroomsData.assignmentsWithoutChecklistGroupedByClassroom;

const isSaving = ref(false);
const showErrors = ref(false);

const saveAssignments = async () => {
  showErrors.value = false;
  /// first hceck if any assignments are missing a checklist
  const missingChecklists = Object.values(
    assignmentsWithoutChecklistGroupedByClassroom
  )
    .flat()
    .filter((assignment) => !assignment.checklistId);

  if (missingChecklists.length > 0) {
    useBaseToast("Please select a checklist for each assignment.", "error");
    showErrors.value = true;
    return;
  }

  isSaving.value = true;

  const assignmentsToSave = Object.values(
    assignmentsWithoutChecklistGroupedByClassroom
  ).flat();
  AssignmentsService.saveAssignments(assignmentsToSave);

  isSaving.value = false;

  useBaseToast("Assignments saved!", "success");
  emit("close");
};

const checklistPath = computed(() => {
  return (checklistId: string) => {
    return (
      checklists.value.find((c) => c.id === checklistId)?.path ?? "/checklists"
    );
  };
});
</script>

<template>
  <VueFinalModal
    class="assignment-checklists-modal"
    content-class="assignment-checklists-modal-content"
    overlay-transition="vfm-fade"
    content-transition="vfm-fade"
  >
    <NuxtLayout
      name="dialog"
      title="Missing Checklists"
      @close="$emit('close')"
    >
      <div class="bg-surface rounded flex flex-col p-4 h-full">
        <p class="text-danger-default italic">
          Some of your assignments are missing checklists. Assign a checklist to
          ensure student documents are imported correctly.
        </p>
        <div class="grow min-h-0 overflow-auto flex flex-col">
          <div
            v-for="(
              assignments, classroomName
            ) in assignmentsWithoutChecklistGroupedByClassroom"
            :key="classroomName"
            class="flex flex-col my-4"
          >
            <h2 class="font-bold text-h6 border-b py-2 mb-4">
              {{ classroomName }}
            </h2>
            <div
              v-for="assignment in assignments"
              :key="assignment.id"
              class="flex flex-col border-b last:border-none py-4 px-4"
              :class="{
                ' bg-danger-default bg-opacity-10':
                  showErrors && !assignment.checklistId,
              }"
            >
              <label class="text-body-1 font-bold mb-2">
                Assignment: {{ assignment.name }}
              </label>
              <BaseSelect
                v-model="assignment.checklistId"
                :options="checklists"
                placeholder="Choose a checklist..."
                value-prop="id"
                label="title"
                :searchable="true"
                @update:model-value="
                  assignment.checklistPath = checklistPath($event)
                "
              />
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-end py-2 border-t">
          <BaseButton
            class="w-[200px]"
            :show-spinner="isSaving"
            @click="saveAssignments"
          >
            Save
          </BaseButton>
        </div>
      </div>
    </NuxtLayout>
  </VueFinalModal>
</template>

<style>
.assignment-checklists-modal {
  z-index: 9999 !important;
}
.assignment-checklists-modal-content {
  display: flex;
  flex-direction: column;

  width: 600px;
  height: 90%;
  margin: auto;
  @apply rounded;
  margin-top: 64px;
}
</style>
