<script lang="ts" setup>
import { Announcement } from "~/classes/models/announcements/announcement.model";

const props = defineProps({
  announcement: {
    type: Object as PropType<Announcement>,
    required: true,
  },
  unviewedAnnouncementIds: {
    type: Array as PropType<String[]>,
    required: true,
  },
});

const isNew = computed(() => {
  return (
    props.announcement.id == undefined ||
    props.unviewedAnnouncementIds.includes(props.announcement.id)
  );
});
</script>

<template>
  <BaseNotificationBadge
    :label="isNew ? '!' : undefined"
    :top="22"
    :left="-18"
    :size="25"
    :font-size="12"
    class="w-full"
  >
    <div class="border-2 my-4 rounded bg-surface w-full">
      <div
        class="flex flex-row items-center w-full border-b px-4 py-2 justify-between"
      >
        <h2 class="text-h4">
          {{ announcement.title }}
        </h2>
        <span class="ml-2 mt-1 italic text-[12px]">
          Last updated
          {{ useBasicDateTimeFormatter(announcement.updatedAtTimestamp) }}
        </span>
      </div>
      <div class="px-4 py-2">
        <BaseTextEditor :model-value="announcement.content" :read-only="true" />
      </div>
    </div>
  </BaseNotificationBadge>
</template>

<style scoped></style>
