<script lang="ts" setup>

const userClassroomsData = useUserClassroomsDataStore();
const { classrooms, allAssignments, allStudents } =
  storeToRefs(userClassroomsData);

const isCreatingDemo = ref(false);
const createDemo = async () => {
  isCreatingDemo.value = true;

  await $fetch(`/api/admin/demos/create-demo`, {
    method: "POST",
    headers: await useApiHeaders(),
    body: {
      classrooms,
      assignments: allAssignments.value,
      students: allStudents.value,
    },
  });

  isCreatingDemo.value = false;
};
</script>

<template>
  <BaseTextButton
    class="mr-2"
    :show-spinner="isCreatingDemo"
    @click="createDemo"
  >
    Create Demo
  </BaseTextButton>
</template>

<style scoped></style>
