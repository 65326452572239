<script lang="ts" setup>
import { Classroom } from "~/classes/models/classrooms/classroom.model";

const props = defineProps({
  classroom: {
    type: Object as PropType<Classroom>,
    required: true,
  },
});

const isSaving = ref(false);

const toggleArchive = async () => {
  isSaving.value = true;
  props.classroom.isArchived = !props.classroom.isArchived;
  await props.classroom.save();

  isSaving.value = false;
};
</script>

<template>
  <div class="flex flex-row items-center justify-between">
    <h2 class="text-h5">
      {{ classroom.label ?? classroom.name }}
    </h2>
    <div class="flex flex-row items-center">
      <BaseTextButton
        v-if="classroom.isArchived == true"
        @click="toggleArchive"
        :show-spinner="isSaving"
      >
        Unarchive
      </BaseTextButton>

      <BaseTextButton
        color="danger"
        v-if="classroom.isArchived != true"
        @click="toggleArchive"
        :show-spinner="isSaving"
      >
        Archive
      </BaseTextButton>
    </div>
  </div>
</template>

<style scoped></style>
