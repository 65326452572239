<script lang="ts" setup>
import { Assignment } from "classes/models/assignments/assignment.model";

const classroomsDataStore = useUserClassroomsDataStore();
const { activeAssignments, classrooms } = storeToRefs(classroomsDataStore);

const classroomName = computed(() => {
  return (assignment: Assignment) => {
    const classroom = classrooms.value.find(
      (classroom) => classroom.id == assignment.classroomId
    );

    return classroom?.label ?? assignment.classroomName;
  };
});
</script>

<template>
  <div class="mb-2 max-h-[170px] border rounded">
    <div class="flex flex-col h-full overflow-auto">
      <div
        v-for="assignment in activeAssignments"
        :key="assignment.id"
        class="flex flex-col border-b last:border-none px-4 py-2"
      >
        <span class="whitespace-nowrap">
          {{ classroomName(assignment) }} - {{ assignment.name }}
        </span>
        <ClassroomAssignmentProgress
          :classroom-id="assignment.classroomId"
          :assignment-id="assignment.id!"
          class="max-w-[400px]"
          :always-show="true"
        />
      </div>
    </div>
  </div>
</template>

<style scoped></style>
