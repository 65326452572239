<template>
  <div class="relative inline-block">
    <slot />
    <transition name="badge-pop" mode="out-in">
      <div
        v-if="hasLabel"
        :key="labelKey"
        class="absolute transform translate-x-1/2 -translate-y-1/2 bg-danger-default text-danger-on rounded-full flex items-center justify-center"
        :style="{
          top: `${top}px`,
          left: `${left}px`,
          width: `${size}px`,
          height: `${size}px`,
          fontSize: `${fontSize}px`,
        }"
      >
        {{ label }}
      </div>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { computed, ref, watch } from "vue";

const props = defineProps({
  label: {
    type: [String, Number],
    default: null,
  },
  top: {
    type: Number,
    default: 6,
  },
  left: {
    type: Number,
    default: -15,
  },
  size: {
    type: Number,
    default: 20,
  },
  fontSize: {
    type: Number,
    default: 12,
  },
});

const hasLabel = computed(() => props.label !== null && props.label !== "");

const labelKey = ref(props.label);

watch(
  () => props.label,
  (newVal) => {
    labelKey.value = newVal;
  }
);
</script>

<style>
/* Animation classes for the transition */
.badge-pop-enter-active,
.badge-pop-leave-active {
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;
}

.badge-pop-enter-from,
.badge-pop-leave-to {
  transform: translateX(50%) translateY(-50%) scale(0);
  opacity: 0;
}

.badge-pop-enter-to,
.badge-pop-leave-from {
  transform: translateX(50%) translateY(-50%) scale(1);
  opacity: 1;
}
</style>
