import {
  collection,
  doc,
  getDocs,
  limit,
  orderBy,
  query,
  startAt,
} from "firebase/firestore";
import { Article } from "types/Article";

export const useUserTips = () => {
  const getUserTip = async (): Promise<Article | undefined> => {
    try {
      const appUserData = useAppUserData();

      if (appUserData.hideTips == true) return undefined;

      if (appUserData.lastViewedTipDate == useCurrentDateString())
        return undefined;

      const nextTipIndex = appUserData.lastViewedTipIndex + 1;

      const db = useFirestore();
      const tipCollectionRef = collection(db, `articles`);
      const tipCollectionQuery = query(
        tipCollectionRef,
        orderBy("tipDisplayOrder", "asc"),
        limit(1),
        startAt(nextTipIndex)
      );

      const tipCollectionSnapshot = await getDocs(tipCollectionQuery);

      if (tipCollectionSnapshot.empty) return undefined;

      const tipDoc = tipCollectionSnapshot.docs[0];
      const tipData = tipDoc.data();
      const article = {
        ...tipData,
        id: tipDoc.id,
      } as Article;

      // Here we will also update the user's lastviewed tip info.
      await appUserData.updateUserLastViewedTip(article);

      return article;
    } catch (error) {
      return undefined;
    }
  };

  return {
    getUserTip,
  };
};
