<script lang="ts" setup>
import {
  DocumentData,
  QueryDocumentSnapshot,
  Unsubscribe,
  collection,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css"; //required if you're not going to override default slots
import { DocumentState } from "~/types/enums/DocumentState";

const props = defineProps({
  states: {
    type: Array as PropType<DocumentState[]>,
    required: true,
  },

  assignmentId: {
    type: String,
    required: false,
  },
  levelId: {
    type: String,
    required: false,
  },
  lessonId: {
    type: String,
    required: false,
  },

  align: {
    type: String as PropType<"left" | "right" | "center">,
    default: "center",
  },
});

const subscription = ref<Unsubscribe | undefined>(undefined);

const documents = ref<SubmittedDocuments>([]);

const lastVisible = ref<QueryDocumentSnapshot<DocumentData> | undefined>(
  undefined
);

const appUserStore = useAppUserData();

const sortingOptions = computed(() => {
  return [
    {
      label: "Last Updated",
      value: "lastUpdatedTimestamp",
    },
    {
      label: "Submitted Date",
      value: "submittedAtTimestamp",
    },
    {
      label: "Creation Date",
      value: "createOnTimestamp",
    },
  ];
});
const sortKey = ref<
  | "lastUpdatedTimestamp"
  | "submittedAtTimestamp"
  | "createOnTimestamp"
  | undefined
>("lastUpdatedTimestamp");
const sortDirection = ref<"desc" | "asc">("desc");

const loadMoreDocuments = () => {
  const uid = useCurrentUID();

  if (uid == undefined) {
    return;
  }

  const db = useFirestore();
  const documentsRef = collection(db, "documents");
  let documentsQuery = query(
    documentsRef,
    where("userId", "==", uid),
    where("state", "in", props.states)
  );

  if (props.assignmentId) {
    documentsQuery = query(
      documentsQuery,
      where("assignmentId", "==", props.assignmentId)
    );
  }

  if (props.levelId) {
    documentsQuery = query(
      documentsQuery,
      where("levelId", "==", props.levelId)
    );
  }

  if (props.lessonId) {
    documentsQuery = query(
      documentsQuery,
      where("lessonId", "==", props.lessonId)
    );
  }

  if (lastVisible.value == undefined) {
    documentsQuery = query(
      documentsQuery,
      orderBy(sortKey.value!, sortDirection.value),
      limit(10)
    );
  } else {
    documentsQuery = query(
      documentsQuery,
      orderBy(sortKey.value!, sortDirection.value),
      startAfter(lastVisible.value),
      limit(10)
    );
  }

  if (subscription.value != undefined) {
    subscription.value();
  }

  subscription.value = onSnapshot(documentsQuery, (snapshot) => {
    lastVisible.value = snapshot.docs[snapshot.docs.length - 1];
    const docs = snapshot.docs.map((doc) => {
      return {
        id: doc.id,
        ...doc.data(),
      } as SubmittedDocument;
    });

    let newList = [...documents.value, ...docs];
    // Remove duplicates
    newList = newList.filter(
      (v, i, a) => a.findIndex((t) => t.id === v.id) === i
    );

    documents.value = newList;
  });
};

onMounted(() => {
  loadMoreDocuments();
});

onUnmounted(() => {
  if (subscription.value != undefined) {
    subscription.value();
  }
});

const { hasIEWLMS } = storeToRefs(appUserStore);

const filteredDocuments = computed(() => {
  return documents.value.filter((doc) => {
    if (
      hasIEWLMS.value != true &&
      doc.documentSubmissionType != undefined &&
      doc.documentSubmissionType != DocumentSubmissionType.finalDraft
    ) {
      return false;
    }

    return true;
  });
});
</script>

<template>
  <div class="overflow-hidden flex flex-col pb-8 px-2 w-full pt-4">
    <DocumentsItem
      v-for="document in filteredDocuments"
      :key="`${document.id}-${document.state}`"
      :submitted-document="document"
      class="mb-4 rounded shadow max-w-[800px] w-full"
      :style="{
        marginLeft: align === 'right' || align == 'center' ? 'auto' : '0',
        marginRight: align === 'left' || align == 'center' ? 'auto' : '0',
      }"
    />
    <InfiniteLoading class="invisible" @infinite="loadMoreDocuments" />
  </div>
</template>

<style scoped></style>
