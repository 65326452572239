<script lang="ts" setup>
const modes = ref([
  {
    label: "IEW LMS",
    value: "iew",
  },
  {
    label: "3rd Party LMS",
    value: "google",
  },
]);

const appUserStore = useAppUserData();
const { demoMode } = storeToRefs(appUserStore);
</script>

<template>
  <div class="flex flex-row items-center p-2">
    <BaseSelect
      v-model="demoMode"
      :options="modes"
      label="label"
      value-prop="value"
      :fixed-position-options="true"
      :can-clear="false"
    />
  </div>
</template>

<style scoped></style>
