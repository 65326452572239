<script lang="ts" setup></script>

<template>
  <div class="flex flex-col">
    <div class="mb-2">You can't log out from the demo account.</div>
    <div class="mb-2">
      Please visit
      <a class=" text-primary-default underline" href="https://iewgradebook.com">https://iewgradebook.com</a> to log in
      to an IEW Gradebook account.
    </div>
  </div>
</template>

<style scoped></style>
