<script lang="ts" setup>
import { Classroom } from "~/classes/models/classrooms/classroom.model";

const props = defineProps({
  classroom: {
    type: Object as PropType<Classroom>,
    required: true,
  },
});

const uid = useCurrentUID();

if (!uid || !props.classroom.id) {
  throw new Error("UID is not defined");
}

const userClassroomDataStore = useClassroomDataStore(uid, props.classroom.id);
const { numUngradedDocuments, assignments } = storeToRefs(
  userClassroomDataStore
);

const appUserDataStore = useAppUserData();
const { hasIEWLMS, isPublicDemo } = storeToRefs(appUserDataStore);

const isSyncing = ref(false);
const syncClassroomSubmissions = async () => {
  if (isPublicDemo.value) {
    useBaseAlert("Oops!", "You can't sync in demo mode.");
    return;
  }

  isSyncing.value = true;

  for (const assignment of assignments.value) {
    await assignment.syncSubmissions();
  }

  isSyncing.value = false;
};
</script>

<template>
  <NuxtLink :to="`/classrooms/${classroom.id}`">
    <BaseCard
      class="p-4 flex flex-col w-full items-center justify-center cursor-pointer hover:bg-light-default overflow-clip relative"
    >
      <icon
        name="mdi:google-classroom"
        size="50"
        class="mb-2 text-primary-default"
      />
      <label
        class="text-h5 cursor-pointer text-ellipsis whitespace-nowrap w-full text-center truncate"
      >
        {{ classroom.label }}
      </label>
      <div class="flex flex-row items-center mt-1">
        <span v-if="numUngradedDocuments && numUngradedDocuments > 0">
          Ungraded ({{ numUngradedDocuments }})
        </span>
        <BaseTextButton
          v-if="hasIEWLMS != true"
          :show-spinner="isSyncing"
          class="mx-1"
          @click.stop.prevent="syncClassroomSubmissions"
        >
          <icon name="material-symbols:sync" />
          <span> Sync </span>
        </BaseTextButton>
      </div>
      <span
        v-if="
          numUngradedDocuments == undefined ||
          (numUngradedDocuments == 0 && hasIEWLMS == true)
        "
      >
        &nbsp;
      </span>
    </BaseCard>
  </NuxtLink>
</template>

<style scoped></style>
