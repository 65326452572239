<script lang="ts" setup>
import { Subscription } from "rxjs";
import { useModal } from "vue-final-modal";
import { Announcements } from "~/classes/models/announcements/announcement.model";
import { AnnouncementsService } from "~/classes/models/announcements/announcements.service";
import {
UserAnnouncementLog,
UserAnnouncementLogs,
} from "~/classes/models/announcements/user-announcement-logs.model";

import AnnouncementsModal from "~/components/Announcements/Modal.vue";

const announcements = ref<Announcements>([]);
const userAnnouncementLogs = ref<UserAnnouncementLogs>([]);

const announcementsSubscription = ref<Subscription | undefined>();
const userAnnouncementLogsSubscription = ref<Subscription | undefined>();

const isAnnouncementsInitialized = ref(false);
const isUserAnnouncementLogsInitialized = ref(false);

const appDataStore = useAppUserData();
const { createdOnTimestamp } = storeToRefs(appDataStore);

onMounted(() => {
  const uid = useCurrentUID();

  if (uid == undefined) {
    return;
  }

  announcementsSubscription.value?.unsubscribe();
  announcementsSubscription.value =
    AnnouncementsService.streamAllPublishedAnnouncements().subscribe((data) => {
      announcements.value = data;
      isAnnouncementsInitialized.value = true;
    });

  userAnnouncementLogsSubscription.value?.unsubscribe();
  userAnnouncementLogsSubscription.value =
    AnnouncementsService.streamUserAnnouncementLogs({
      userId: uid,
    }).subscribe((data) => {
      userAnnouncementLogs.value = data;
      isUserAnnouncementLogsInitialized.value = true;
    });
});

onUnmounted(() => {
  announcementsSubscription.value?.unsubscribe();
  userAnnouncementLogsSubscription.value?.unsubscribe();
});

const userAnnouncemnetLogsIds = computed(() => {
  return [
    ...new Set(userAnnouncementLogs.value.map((log) => log.announcementId)),
  ];
});

const unviewedAnnouncements = computed(() => {
  return announcements.value.filter((announcement) => {
    if (
      createdOnTimestamp.value != undefined &&
      announcement.updatedAtTimestamp < createdOnTimestamp.value
    ) {
      return false;
    }

    const announcementLogs = userAnnouncementLogs.value.filter(
      (log) => log.announcementId == announcement.id
    );

    // Sort by viewedAtTimestamp
    const announcementLog = announcementLogs.sort(
      (a, b) => b.createdAtTimestamp - a.createdAtTimestamp
    )[0];

    const viewedAtTimestamp = announcementLog?.createdAtTimestamp;
    
    if (
      viewedAtTimestamp != undefined &&
      announcement.updatedAtTimestamp > viewedAtTimestamp
    ) {
      return true;
    }

    return (
      announcement.id == undefined ||
      userAnnouncemnetLogsIds.value.includes(announcement.id) != true
    );
  });
});

const numUnviewedReleaeNotes = computed(() => {
  return unviewedAnnouncements.value.length;
});

const showAnnouncements = () => {
  const { open, close } = useModal({
    component: AnnouncementsModal,
    attrs: {
      announcements: announcements.value,
      unviewedAnnouncementIds: unviewedAnnouncements.value.map(
        (announcemnet) => announcemnet.id!
      ),
      onClose: () => {
        close();
      },
      onBeforeClose: () => {
        clearUnviewedAnnouncements();
      },
    },
  });

  open();
};

const isClearing = ref(false);

const clearUnviewedAnnouncements = async () => {
  const uid = useCurrentUID();

  if (uid == undefined) {
    return;
  }

  if (isClearing.value) return;

  isClearing.value = true;

  const userLogs: UserAnnouncementLogs = unviewedAnnouncements.value.map(
    (announcement) => {
      return new UserAnnouncementLog({
        userId: uid,
        announcementId: announcement.id!,
        createdAtTimestamp: Date.now(),
      });
    }
  );

  await AnnouncementsService.saveUserLogs({
    userLogs: userLogs,
  });

  isClearing.value = false;
};

const badgeLabel = computed(() => {
  if (
    isAnnouncementsInitialized.value != true ||
    isUserAnnouncementLogsInitialized.value != true
  )
    return undefined;

  return numUnviewedReleaeNotes.value > 0
    ? numUnviewedReleaeNotes.value
    : undefined;
});
</script>

<template>
  <BaseNotificationBadge :label="badgeLabel">
    <BaseTextButton v-tooltip="'Announcements'" @click="showAnnouncements">
      <icon name="mdi:party-popper" size="25" />
    </BaseTextButton>
  </BaseNotificationBadge>
</template>

<style>
#HW_badge_cont {
  position: absolute !important;
}

#HW_badge {
  position: absolute !important;
  top: 0px !important;
  left: -2px !important;
}
</style>
